import { forwardRef, ReactNode } from "react";
import {
  Text as RNText,
  TextProps as RNTextProps,
  StyleSheet,
  TextStyle,
} from "react-native";

import Token from "../Token";

export type TextRef = RNText;

export type TextProps = {
  align?: "left" | "center" | "right";
  children?: ReactNode;
  /**
   * Defines color of the text.
   * @default 'primary'
   */
  ink?:
    | "black-primary"
    | "black-secondary"
    | "black-muted"
    | "black-neutral"
    | "white-primary"
    | "white-secondary"
    | "white-muted"
    | "white-neutral"
    | "interactive"
    | "destructive"
    | "highlight"
    | "price"
    | "positive"
    | "alert"
    | "warning";
  /**
   * Defines size & weight of the text.
   * @default 'ui-baseline'
   **/
  variant?:
    | "hero"
    | "display"
    | "headline"
    | "title-1"
    | "title-2"
    | "title-3"
    | "ui-large"
    | "ui-baseline"
    | "ui-small"
    | "ui-tiny"
    | "ui-large-bold"
    | "ui-baseline-bold"
    | "ui-small-bold"
    | "ui-tiny-bold"
    | "caption-tiny"
    | "caption-micro"
    | "button-large"
    | "button-medium"
    | "button-small";
} & RNTextProps;

export default forwardRef<TextRef, TextProps>(function Text(props, ref) {
  const {
    align = "left",
    ink = "black-primary",
    style,
    variant = "ui-baseline",
    ...rest
  } = props;

  const rootStyles = [
    styles.root,
    styles[variant],
    styles[ink],
    styles[align],
    style,
  ];

  return <RNText {...rest} ref={ref} style={rootStyles} />;
});

const styles = StyleSheet.create({
  root: {
    wordBreak: "break-word",
  } as TextStyle,

  // Variant
  hero: Token.typography.hero,
  display: Token.typography.display,
  headline: Token.typography.headline,
  "title-1": Token.typography.title1,
  "title-2": Token.typography.title2,
  "title-3": Token.typography.title3,
  "ui-large": Token.typography.uiLarge,
  "ui-baseline": Token.typography.uiBaseline,
  "ui-small": Token.typography.uiSmall,
  "ui-tiny": Token.typography.uiTiny,
  "ui-large-bold": Token.typography.uiLargeBold,
  "ui-baseline-bold": Token.typography.uiBaselineBold,
  "ui-small-bold": Token.typography.uiSmallBold,
  "ui-tiny-bold": Token.typography.uiTinyBold,
  "caption-tiny": Token.typography.captionTiny,
  "caption-micro": Token.typography.captionMicro,
  "button-large": Token.typography.buttonLarge,
  "button-medium": Token.typography.buttonMedium,
  "button-small": Token.typography.buttonSmall,

  // Ink
  "black-primary": { color: Token.color.darkPrimary },
  "black-secondary": { color: Token.color.darkSecondary },
  "black-muted": { color: Token.color.lightSecondary },
  "black-neutral": { color: Token.color.darkNeutral },
  "white-primary": { color: Token.color.lightPrimary },
  "white-secondary": { color: Token.color.lightSecondary },
  "white-muted": { color: Token.color.darkSecondary },
  "white-neutral": { color: Token.color.lightNeutral },
  interactive: { color: Token.color.bluePrimary },
  destructive: { color: Token.color.redPrimary },
  highlight: { color: Token.color.blueDark },
  price: { color: Token.color.orangePrimary },
  positive: { color: Token.color.greenDark },
  alert: { color: Token.color.redDark },
  warning: { color: Token.color.yellowDark },

  // Align
  left: { textAlign: "left" },
  center: { textAlign: "center" },
  right: { textAlign: "right" },
});
