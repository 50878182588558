import { CSSProperties, PropsWithChildren, useMemo } from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";

import useHover from "../../hooks/useHover";
import Token from "../../Token/Token";

type Props = {
  style?: StyleProp<ViewStyle>;
  columnLengths: (string | number)[];
  disableHover?: boolean;
  hasAction?: boolean;
  onPress?(): void;
};

export default function PaginationTableRow(props: PropsWithChildren<Props>) {
  const {
    children,
    disableHover = false,
    columnLengths,
    hasAction,
    onPress,
    style,
  } = props;
  const [hover, handlers] = useHover();
  const gridStyles = useMemo<CSSProperties>(() => {
    const templateColumns = columnLengths
      .map((val) => {
        const parsedVal = typeof val === "number" ? `${val}px` : val;
        return !val ? "1fr" : parsedVal;
      })
      .join(" ");

    return {
      display: "grid",
      gridTemplateColumns: `${templateColumns} ${hasAction ? "64px" : ""}`,
    };
  }, [columnLengths, hasAction]);

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      disabled={disableHover}
      onPress={onPress}
      style={[
        gridStyles as StyleProp<ViewStyle>,
        styles.rows,
        !disableHover && hover && styles.rowHover,
        style,
      ]}
      {...handlers}
    >
      {children}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  rows: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.s,
    backgroundColor: Token.color.lightPrimary,
    columnGap: Token.spacing.l,
    // @ts-ignore
    transition: `${Token.timing.instant}ms all !important`,
  },
  rowHover: {
    zIndex: 2,
    // @ts-ignore
    boxShadow: Token.shadow.raised,
    cursor: "pointer",
  },
  rowsEven: {
    backgroundColor: Token.color.lightStain,
  },
});
