import { parse } from "date-fns";
import { useCallback, useMemo } from "react";

import { Locale } from "../constants";
import { useLocale } from "../contexts/LocaleContext";

export type Format =
  | WeekdayFormat
  | GeneralDateFormat
  | MonthYearFormat
  | MonthOnlyFormat;
type WeekdayFormat =
  | "SHORT_WEEKDAY" // EEE, dd MMM yyyy
  | "FULL_WEEKDAY" // EEEE, dd MMM yyyy
  | "FULL_WEEKDAY_MONTH"; // EEEE, dd MMMM yyyy
type GeneralDateFormat =
  | "SHORT_MONTH" // dd MMM yyyy
  | "SHORT_MONTH_2" // dd MMM yy
  | "FULL_MONTH"; // dd MMMM yyyy
type MonthYearFormat =
  | "SHORT_MONTH_NO_DAY" // MMM yyyy
  | "FULL_MONTH_NO_DAY"; // MMMM yyyy
type MonthOnlyFormat = "FULL_MONTH_ONLY"; // MMMM

function useFormat() {
  const locale = useLocale();

  return useCallback(
    (
      date: Date | number,
      dateFormat: Format,
      withTime: boolean = false,
      restructure: boolean = true
    ) => {
      try {
        const options = formatToIntlFormat(dateFormat, withTime);
        const formattedDate = new Intl.DateTimeFormat(
          localeToIntlLocale(locale),
          options
        ).format(date);

        if (!restructure) {
          return formattedDate;
        }

        switch (dateFormat) {
          case "FULL_WEEKDAY":
          case "FULL_WEEKDAY_MONTH":
          case "SHORT_WEEKDAY":
            return restructureWeekdayFormat(formattedDate, locale, withTime);
          case "FULL_MONTH":
          case "SHORT_MONTH":
          case "SHORT_MONTH_2":
            return restructureGeneralDateFormat(
              formattedDate,
              locale,
              withTime
            );
          case "FULL_MONTH_NO_DAY":
          case "SHORT_MONTH_NO_DAY":
            // Date is: MMM yyyy
            // No need to reconstruct for EN-ID Locale
            return formattedDate;
          default:
            return formattedDate;
        }
      } catch (error) {
        return "";
      }
    },
    [locale]
  );
}

export function useLocalizedDateFormat() {
  const format = useFormat();

  return useMemo(
    () => ({
      format,
      changeFormat: (
        date: string,
        from: string,
        to: Format,
        baseDate: number | Date = 0
      ) => {
        try {
          return format(parse(date, from, baseDate), to);
        } catch (error) {
          return "";
        }
      },
      formatWithOffset: (dateString: string, dateFormat: Format) => {
        try {
          const date = new Date(dateString.replace(" ", "T") + "Z");
          return format(date, dateFormat, true);
        } catch (error) {
          return "";
        }
      },
    }),
    [format]
  );
}

function restructureWeekdayFormat(
  date: string,
  locale: Locale,
  withTime?: boolean
) {
  if (locale === Locale.ENID) {
    // Date is: EEE, MMM dd, yyyy, HH:mm
    // Convert to: EEE, dd MMM yyyy HH:mm

    const splitComma = date.split(", "); // [Weekday, "Month Day", Year, (withTime && "HH:mm")]
    const splitMonthDay = splitComma[1].split(" "); // [Month, Day]

    return `${splitComma[0]}, ${splitMonthDay[1]} ${splitMonthDay[0]} ${
      splitComma[2]
    }${withTime ? ` ${splitComma[3]}` : ""}`;
  }

  return date;
}

function restructureGeneralDateFormat(
  date: string,
  locale: Locale,
  withTime?: boolean
) {
  if (locale === Locale.ENID) {
    // Date is: MMM dd, yyyy
    // Convert to: dd MMM yyyy

    const splitComma = date.split(", "); // ["Month Day", Year, (withTime && "HH:mm")]
    const splitMonthDay = splitComma[0].split(" "); // [Month, Day]
    const timeValue = withTime ? ` ${splitComma[2]}` : "";

    return `${splitMonthDay[1]} ${splitMonthDay[0]} ${splitComma[1]}${timeValue}`;
  }

  return date;
}

function localeToIntlLocale(locale: Locale) {
  switch (locale) {
    case Locale.ENID:
      return "en-US";
    case Locale.IDID:
      return "id-ID";
  }
}

/*
    Source:
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
    https://devhints.io/wip/intl-datetime
*/
type IntlOptions = {
  weekday?: "long" | "short" | "narrow";
  era?: "long" | "short" | "narrow";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  fractionalSecondDigits?: 1 | 2 | 3;
  timeZoneName?: "long" | "short";
  hour12?: boolean;
};
const formatToIntlFormat = (
  format: Format,
  withTime?: boolean
): IntlOptions => {
  let options: IntlOptions;
  switch (format) {
    // Weekday
    case "SHORT_WEEKDAY":
      options = {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      break;
    case "FULL_WEEKDAY":
      options = {
        weekday: "long",
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      break;
    case "FULL_WEEKDAY_MONTH":
      options = {
        weekday: "long",
        day: "2-digit",
        month: "long",
        year: "numeric",
      };
      break;

    // General Date
    case "SHORT_MONTH":
      options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      break;
    case "SHORT_MONTH_2":
      options = {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      };
      break;
    case "FULL_MONTH":
      options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
      };
      break;

    // Month Year
    case "SHORT_MONTH_NO_DAY":
      options = {
        month: "short",
        year: "numeric",
      };
      break;
    case "FULL_MONTH_NO_DAY":
      options = {
        month: "long",
        year: "numeric",
      };
      break;

    // Month Only
    case "FULL_MONTH_ONLY":
      options = {
        month: "long",
      };
      break;
  }

  if (withTime) {
    options.hour = "2-digit";
    options.minute = "2-digit";
    options.hour12 = false;
  }

  return options;
};
