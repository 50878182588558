"use client";
import { cloneElement, forwardRef, ReactElement, useRef } from "react";
import {
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from "react-native";

import useFocus from "../../hooks/useFocus";
import useHover from "../../hooks/useHover";
import Token from "../../Token";
import View from "../../View";

type RNTextInputProps = Omit<
  TextInputProps,
  "style" | "accessible" | "placeholderTextColor"
>;

type Variant = "formal" | "minimal";

type Size = "medium" | "small";

export type InputRef = HTMLElement;

export type InputProps = {
  /**
   * If true, text is greyed out
   */
  disabled?: boolean;
  /**
   * Element placed after the children.
   * Icon should be resized according to spec
   * - `medium`: 16px
   * - `small`: 12px
   */
  endIcon?: ReactElement;
  /**
   * Error state
   * @default false
   */
  error?: boolean;
  /**
   * ID of an element describing the help text
   */
  helperId?: string;
  /**
   * Custom style applied to the input element
   */
  inputStyle?: StyleProp<TextStyle>;
  /**
   * ID of an element describing the label
   */
  labelId?: string;
  /**
   * Size of the input
   */
  size?: Size;
  /**
   * Element placed before the children.
   * Icon should be resized according to spec
   * - `medium`: 24px
   * - `small`: 16px
   */
  startIcon?: ReactElement;
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<ViewStyle>;
  /**
   * @default 'formal'
   */
  variant?: Variant;
  /**
   * To enable icon start focus feedback
   */
  isStartIconClickable?: boolean;
  /**
   * To enable icon end focus feedback
   */
  isEndIconClickable?: boolean;
} & RNTextInputProps;

const iconSizeMap = {
  medium: [24, 16],
  small: [16, 12],
};

export default forwardRef<InputRef, InputProps>(function Input(props, ref) {
  const {
    disabled = false,
    editable = true,
    error = false,
    // errorId,
    helperId,
    endIcon,
    startIcon,
    labelId,
    onBlur,
    onFocus,
    size = "medium",
    variant = "formal",
    style,
    isStartIconClickable,
    isEndIconClickable,
    ...textInputProps
  } = props;

  const localInputRef = useRef<InputRef>(null);
  const [startSize, endSize] = iconSizeMap[size];
  const [hovered, hoverHandler] = useHover();
  const [focused, focusHandler] = useFocus({ onBlur, onFocus });

  const [containerStyle, focusedStyle] = getVariantStyle(variant, size);

  const usedRef = ref || localInputRef;

  return (
    <View
      {...hoverHandler}
      align="center"
      row
      spacing="xs"
      style={[
        styles.root,
        containerStyle,
        hovered && styles.rootHovered,
        error && styles.rootError,
        focused && styles.rootFocused,
        focused && focusedStyle,
        disabled && styles.rootDisabled,
        style,
      ]}
    >
      {startIcon &&
        (isStartIconClickable ? (
          // @ts-expect-error -- Typing is incorrect
          <TouchableOpacity onPress={() => ref?.current?.focus()}>
            {cloneElement(startIcon, { height: startSize, width: startSize })}
          </TouchableOpacity>
        ) : (
          cloneElement(startIcon, { height: startSize, width: startSize })
        ))}
      <TextInput
        {...textInputProps}
        {...focusHandler}
        // @ts-expect-error -- ref from text input will be compiled to the react since we are using react-native-web
        ref={usedRef}
        accessible={!disabled}
        aria-describedby={helperId}
        aria-errormessage={error ? helperId : undefined}
        aria-invalid={String(error)}
        aria-labelledby={labelId}
        accessibilityRole="text"
        disabled={disabled}
        editable={!(disabled || !editable)}
        placeholderTextColor={Token.color.lightSecondary}
        style={[
          styles.input,
          styles[size],
          disabled && styles.inputDisabled,
          textInputProps.inputStyle,
        ]}
        keyboardType="default"
      />
      {endIcon &&
        (isEndIconClickable ? (
          // @ts-expect-error -- Typing is incorrect
          <TouchableOpacity onPress={() => usedRef?.current?.focus()}>
            {cloneElement(endIcon, { height: endSize, width: endSize })}
          </TouchableOpacity>
        ) : (
          cloneElement(endIcon, { height: endSize, width: endSize })
        ))}
    </View>
  );
});

function getVariantStyle(variant: Variant, size: Size) {
  const small = size === "small";

  switch (variant) {
    case "formal":
      return [
        [styles.formal, small && styles.formalSmall],
        [styles.formalFocused, small && styles.formalSmallFocused],
      ] as const;
    case "minimal":
      return [
        [styles.minimal, small && styles.minimalSmall],
        [styles.minimalFocused, small && styles.minimalSmallFocused],
      ] as const;
  }
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: Token.color.lightPrimary,
    borderColor: Token.color.lightSecondary,
    transitionDuration: `${Token.timing.instant}ms`,
    transitionProperty: "background-color, border-color",
    transitionTimingFunction: "ease-in",
  },
  rootDisabled: {
    borderColor: Token.color.lightNeutral,
    backgroundColor: Token.color.lightNeutral,
  },
  rootFocused: {
    borderColor: `rgba(1, 148, 243, .5)`,
  },
  rootError: {
    borderColor: Token.color.redPrimary,
  },
  rootHovered: {
    borderColor: Token.color.darkSecondary,
  },

  input: {
    color: Token.color.darkPrimary,
    flex: 1,
    minWidth: 0,
    outlineStyle: "none",
    outlineWidth: 0,
  },
  inputDisabled: {
    color: Token.color.lightSecondary,
    cursor: "default",
  },

  // Variant
  formal: {
    padding: Token.spacing.xs - Token.borderWidth.thick,
    borderWidth: Token.borderWidth.thick,
    borderRadius: Token.borderRadius.normal,
  },
  minimal: {
    paddingHorizontal: Token.spacing.xxs,
    paddingTop: Token.spacing.xs,
    paddingBottom: Token.spacing.xs - Token.borderWidth.thick,
    borderBottomWidth: Token.borderWidth.thick,
  },
  formalFocused: {
    borderWidth: Token.borderWidth.bold,
    padding: Token.spacing.xs - Token.borderWidth.bold,
  },
  minimalFocused: {
    borderBottomWidth: Token.borderWidth.bold,
    paddingBottom: Token.spacing.xs - Token.borderWidth.bold,
  },

  // Size
  formalSmall: {
    padding: 6 - Token.borderWidth.thick,
  },
  minimalSmall: {
    paddingTop: 6,
    paddingBottom: 6 - Token.borderWidth.thick,
  },
  formalSmallFocused: {
    borderWidth: Token.borderWidth.bold,
    padding: 6 - Token.borderWidth.bold,
  },
  minimalSmallFocused: {
    borderBottomWidth: Token.borderWidth.bold,
    paddingBottom: 6 - Token.borderWidth.bold,
  },
  medium: Token.typography.uiBaseline,
  small: Token.typography.uiSmall,
});
