import { ComponentProps, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputFocusEventData,
} from "react-native";

import { useLocalizedDateFormat } from "../../../core/locale/hooks/useLocalizedDateFormat";
import { createRhfRef } from "../../../utils";
import Token from "../../Token";
import View from "../../View";
import InputGroup from "../Input/InputGroup";
import { InputDropdown } from "../InputDropdown";
import { FieldProps } from "../types";

type InputDropdownProps = ComponentProps<typeof InputDropdown>;

export type DateFieldValue = {
  day: string;
  /**
   * 0 = January
   */
  month: string;
  year: string;
};

export type DateFieldProps = {
  maxYear?: number;
  yearRange?: number;
  reverse?: boolean;
} & FieldProps<DateFieldValue> &
  Omit<InputDropdownProps, "items" | "onPressItem" | "defaultValue">;

export default function DateField(props: DateFieldProps) {
  const {
    defaultValue,
    name,
    validate,
    onFocus,
    label,
    startHelper,
    endHelper,
    disabled,
    style,
    maxYear = new Date().getFullYear(),
    yearRange = 100,
    reverse,
    ...inputProps
  } = props;

  const { format } = useLocalizedDateFormat();
  const { control } = useFormContext();
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  const [yearItems, monthItems] = useMemo(() => {
    const years = Array.from({ length: yearRange }, (v, i) => ({
      label: String(maxYear - i),
      value: String(maxYear - i),
    }));

    if (reverse) {
      years.reverse();
    }

    const months = Array.from({ length: 12 }, (v, i) => ({
      label: format(new Date(0, i), "FULL_MONTH_ONLY"),
      value: String(i),
    }));

    return [years, months];
  }, [maxYear, yearRange, reverse, format]);

  function handleFocus(e: NativeSyntheticEvent<TextInputFocusEventData>) {
    if (typeof onFocus === "function") {
      onFocus(e);
    }
  }

  const displayMonth = value?.month
    ? format(new Date(0, Number(value.month)), "FULL_MONTH_ONLY")
    : "";

  return (
    <InputGroup
      label={label}
      error={error?.message}
      leftHelper={startHelper}
      rightHelper={endHelper}
      style={style}
      disabled={disabled}
    >
      <View ref={createRhfRef(ref)} style={styles.input} row spacing="xs">
        <InputDropdown
          {...inputProps}
          disabled={disabled}
          error={!!error?.message}
          onBlur={onBlur}
          onFocus={handleFocus}
          onPressItem={(item) => onChange({ ...value, day: item.value })}
          value={value?.day ?? ""}
          items={dayItems}
          containerStyle={styles.day}
          style={styles.field}
          editable
          searchable
        />
        <InputDropdown
          {...inputProps}
          error={!!error?.message}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={handleFocus}
          onPressItem={(item) => onChange({ ...value, month: item.value })}
          value={value?.month && displayMonth}
          items={monthItems}
          containerStyle={styles.month}
          style={styles.field}
          editable
          searchable
        />
        <InputDropdown
          {...inputProps}
          error={!!error?.message}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={handleFocus}
          onPressItem={(item) => onChange({ ...value, year: item.value })}
          value={value?.year ?? ""}
          items={yearItems}
          containerStyle={styles.year}
          style={styles.field}
          editable
          searchable
        />
      </View>
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  input: {
    zIndex: 1,
    width: "100%",
  },
  day: {
    flex: 2,
  },
  month: {
    flex: 4,
  },
  year: {
    flex: 3,
  },
  field: {
    paddingVertical: Token.spacing.xxs,
  },
});

const dayItems = Array.from({ length: 31 }, (v, i) => ({
  label: String(i + 1),
  value: String(i + 1),
}));
