"use client";
import { useRouter } from "next/navigation";
import { useMemo } from "react";

import { useLocale } from "../contexts/LocaleContext";

export default function useLocalizedRouterServer() {
  const locale = useLocale();
  const router = useRouter();

  return useMemo(
    () => ({
      push(path: string) {
        router.push(`/${locale}/${path}`.replaceAll("//", "/"));
      },
      replace(path: string) {
        router.replace(`/${locale}/${path}`.replaceAll("//", "/"));
      },
    }),
    [locale, router]
  );
}
