import { Config } from "./types";

const config: Config = {
  auth: {
    audience: "https://tvlk/ctv",
    domain: "tvlk.auth0.com",
    clientId: "LlEnQZQQfZ7TCXInEN8fxJMjLQxwLvSr",
    maxAge: 43200,
  },
  amplify: {
    Auth: {
      authenticationFlowType: "USER_PASSWORD_AUTH",
      region: "ap-southeast-1",
      userPoolId: "ap-southeast-1_5Ra7zeOr1",
      userPoolWebClientId: "1mqd63gb9gcdrtvrkrn1ehl554",

      oauth: {
        domain: "ctv.auth.ap-southeast-1.amazoncognito.com/",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://corporates.ctv.traveloka.com/",
        redirectSignOut: "https://corporates.ctv.traveloka.com/",
        responseType: "code",
      },
    },
  },
  apiHost: {
    search: "https://search-api.ctv.traveloka.com/",
    booking: "https://booking-api.ctv.traveloka.com/",
    refund: "https://refund-api.ctv.traveloka.com/",
    management: "https://management-api.ctv.traveloka.com/",
    content: "https://api.cnt.traveloka.com/",
    data: "https://tracking.prod.tvlk-data.com/",
  },
  datadog: {
    applicationId: "2465fa24-19bf-4d70-bc8e-6d50d404b4b1",
    clientToken: "pub0473568163fa97f2907ee88eefec8a00",
    defaultPrivacyLevel: "mask-user-input",
    env: "prod",
    service: "corporate-travel-web",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "1.0.0",
    sessionReplaySampleRate: 100,
    sessionSampleRate: 10,
    site: "datadoghq.com",
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
  },
  mock: {
    enabled: false,
  },
  securePayment: "https://payfrm.pay.traveloka.com",
};

export default config;
