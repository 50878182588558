import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import Token from '../Token';
import View from '../View';

export type PulseProps = {
  /**
   * Color of the dots
   * @default '#ff5e1f'
   */
  color?: string;
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<ViewStyle>;
};

export default function Pulse(props: PulseProps) {
  const { color = Token.color.orangePrimary, style } = props;

  const dotStyle = { backgroundColor: color };

  return (
    <View style={[styles.root, style]}>
      <View style={[styles.dot, dotStyle]} />
      <View style={[styles.dot, dotStyle, styles.pulse]} />
      <View style={[styles.dot, dotStyle, styles.pulse, styles.last]} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    height: 8,
    justifyContent: 'center',
    width: 8,
  },
  dot: {
    borderRadius: Token.borderRadius.rounded,
    height: '100%',
    width: '100%',
  },
  pulse: {
    /**
     * This animation actually only has 2 steps, but because it needs to wait
     * for other pulse, there will be 4 steps illustrated below.
     *
     * |-----------|-----| First wave
     * S     A     E  W
     * |-----|-----------| Second Wave
     *    W  S     A     E
     * 1     2     3     4 Keyframes
     *
     * S: Start
     * A: Animating
     * E: End
     * W: Waiting
     *
     * From the perspective of first wave, the steps will be as follow
     * 1. First wave start pulsing (S)
     * 2. Second wave start pulsing (A)
     * 3. First wave has fully faded (E)
     * 4. Waiting for second wave to finish (W)
     */
    // @ts-expect-error animationDuration does not exist in typing
    animationDuration: `${Token.timing.slow * 4}ms`,
    animationIterationCount: 'infinite',
    animationKeyframes: {
      '0%': {
        transform: [{ scale: 1 }],
        opacity: 0.5,
      },
      '75%, 100%': {
        transform: [{ scale: 3 }],
        opacity: 0,
      },
    },
    animationTimingFunction: 'ease-out',
    position: 'absolute',
    zIndex: -1,
  },
  last: {
    // @ts-expect-error animationDelay does not exist in typing
    animationDelay: `${Token.timing.slow}ms`,
  },
});
