"use client";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { TouchableOpacity } from "react-native";

import Menu from "@traveloka/icon-kit-web/react/IcSystemMenuMoreHorizontal";

import { formatMessage } from "../../../utils/intl";
import Card from "../../Card/Card";
import DotLoader from "../../DotLoader";
import Fade from "../../Fade/Fade";
import Pagination from "../../Pagination/Pagination";
import Skeleton from "../../Skeleton/Skeleton";
import Text from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";

import PaginationTableHeader from "./PaginationTableHeader";
import PaginationTableRow from "./PaginationTableRow";
import { paginationTableStyles } from "./styles";
import { PaginationTableProps, RowItem } from "./types";

export default function PaginationTable<T extends string>(
  props: PaginationTableProps<T>
) {
  const {
    actions,
    headerCells,
    controlPagination,
    containerStyle,
    customHeader,
    customNotFound,
    disabled = false,
    actionDisabled = false,
    entries,
    loading = false,
    loadingStyle = "dot-loader",
    disablePagination = false,
    itemsPerPage,
    columnOrder,
    onRowPress,
    tableRef,
    paginationDataLabelCr,
  } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [actionState, setActionState] = useState(-1);
  const shownEntries = controlPagination
    ? entries
    : entries.slice(itemsPerPage * (pageNumber - 1), itemsPerPage * pageNumber);
  useEffect(() => {
    !controlPagination && setPageNumber(1);
    setActionState(-1);
  }, [entries, controlPagination]);

  const mappedActions = useMemo(
    () =>
      actions?.map((action) => ({
        ...action,
        onActionPress: (row: RowItem<T>) => {
          setActionState(-1);
          action.onActionPress(row);
        },
      })),
    [actions]
  );
  const hasAction = Boolean(mappedActions?.length);

  return (
    <Card
      ref={tableRef}
      style={[paginationTableStyles.container, containerStyle]}
    >
      <View style={paginationTableStyles.wrapper}>
        {customHeader}
        <PaginationTableHeader {...props} hasAction={hasAction} />
        {loading ? (
          loadingStyle === "dot-loader" ? (
            <DotLoader style={paginationTableStyles.loader} />
          ) : (
            Array.from({ length: 10 }).map((_, index) => (
              <PaginationTableRow
                key={index}
                style={[
                  paginationTableStyles.rows,
                  Boolean(index % 2) && paginationTableStyles.rowsEven,
                ]}
                columnLengths={columnOrder.map(
                  (order) => headerCells[order].length || 0
                )}
                hasAction={hasAction}
              >
                {columnOrder.map((order, index) => {
                  const width = headerCells[order].length ?? "1fr";
                  return (
                    <View key={index}>
                      <Skeleton height={16} width={width} />
                    </View>
                  );
                })}
                {hasAction && <View />}
              </PaginationTableRow>
            ))
          )
        ) : shownEntries.length ? (
          shownEntries.map((row, rowIndex) => {
            const { rowStyle, cells } = row;
            return (
              <>
                <PaginationTableRow
                  style={[
                    !!(rowIndex % 2) && paginationTableStyles.rowsEven,
                    rowIndex === actionState && { zIndex: 3 },
                    rowStyle,
                  ]}
                  columnLengths={columnOrder.map(
                    (order) => headerCells[order].length || 0
                  )}
                  key={rowIndex}
                  onPress={() => {
                    setActionState(-1);
                    onRowPress?.(row);
                  }}
                  disableHover={actionDisabled || disabled}
                  hasAction={hasAction}
                >
                  {columnOrder.map((order, index) => {
                    const cell = cells[order];
                    return (
                      cell.render || (
                        <Text
                          style={paginationTableStyles.cellText}
                          variant="ui-small"
                          key={String(cell.value) + String(index)}
                          ink={disabled ? "black-muted" : "black-primary"}
                        >
                          {cell.label || String(cell.value)}
                        </Text>
                      )
                    );
                  })}
                  {hasAction && (
                    <View
                      style={[
                        paginationTableStyles.menuWrapper,
                        !!(rowIndex % 2) && paginationTableStyles.rowsEven,
                        rowStyle,
                      ]}
                    >
                      <TouchableOpacity
                        disabled={actionDisabled || disabled}
                        onPress={() =>
                          rowIndex !== actionState
                            ? setActionState(rowIndex)
                            : setActionState(-1)
                        }
                      >
                        <Menu
                          color={
                            actionDisabled || disabled
                              ? Token.color.lightSecondary
                              : Token.color.darkSecondary
                          }
                          accentColor={
                            actionDisabled || disabled
                              ? Token.color.lightSecondary
                              : Token.color.bluePrimary
                          }
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                </PaginationTableRow>
                <Fade
                  style={paginationTableStyles.cardWrapper}
                  visible={actionState === rowIndex}
                >
                  <Card
                    style={paginationTableStyles.actionCard}
                    elevation="raised"
                  >
                    {mappedActions?.map((action) => (
                      <TouchableOpacity
                        disabled={
                          typeof action.disabled === "function"
                            ? action.disabled(row)
                            : action.disabled
                        }
                        key={action.actionId}
                        onPress={() => action.onActionPress(row)}
                      >
                        {action.render(row)}
                      </TouchableOpacity>
                    ))}
                  </Card>
                </Fade>
              </>
            );
          })
        ) : (
          customNotFound
        )}

        <View
          style={paginationTableStyles.paginationRow}
          row
          justify="between"
          align="center"
        >
          <Loading loading={loading} width={130} height={20}>
            <Text
              variant="ui-small"
              ink={disabled ? "black-muted" : "black-secondary"}
            >
              {formatMessage(paginationDataLabelCr, {
                shownData: shownEntries.length,
                entries: controlPagination
                  ? controlPagination.totalEntries
                  : entries.length,
              })}
            </Text>
          </Loading>

          {!disablePagination && (
            <Loading loading={loading} width={274} height={36}>
              <Pagination
                disabled={loading || disabled || !entries.length}
                totalPage={
                  controlPagination
                    ? Math.ceil(controlPagination.totalEntries / itemsPerPage)
                    : Math.ceil(entries.length / itemsPerPage)
                }
                page={controlPagination?.page || pageNumber}
                onPageChange={(page) => {
                  controlPagination?.onPageChange(page);
                  setPageNumber(page);
                }}
              />
            </Loading>
          )}
        </View>
      </View>
    </Card>
  );
}

type LoadingProps = {
  loading: boolean;
  height: number;
  width: number;
};

function Loading(props: PropsWithChildren<LoadingProps>) {
  const { loading, children } = props;

  if (loading) {
    return <Skeleton height={props.height} width={props.width} />;
  }

  return <>{children}</>;
}
