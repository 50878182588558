import { useController, useFormContext } from "react-hook-form";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputFocusEventData,
} from "react-native";

import { countries } from "../../../constants";
import { appendTestId } from "../../../utils";
import Token from "../../Token";
import View from "../../View";
import Input from "../Input/Input";
import InputGroup from "../Input/InputGroup";
import { InputDropdownProps } from "../InputDropdown";
import InputDropdown from "../InputDropdown/InputDropdown";
import { InputDropdownItemValue } from "../InputDropdown/types";
import { FieldProps } from "../types";

export type PhoneFieldValue = {
  prefix: string;
  phone: string;
};

export type PhoneFieldProps = FieldProps<PhoneFieldValue> &
  Omit<
    InputDropdownProps<any>,
    "items" | "onPressItem" | "defaultValue" | "onBlur"
  > & {
    testIDPrefix?: string;
    label?: string;
    leftHelper?: string;
    rightHelper?: string;
    onBlur?(value: PhoneFieldValue, onChange: (event: any) => void): void;
  };

export default function PhoneField(props: PhoneFieldProps) {
  const {
    defaultValue,
    name,
    validate,
    onFocus,
    label,
    leftHelper,
    rightHelper,
    disabled,
    style,
    testIDPrefix,
    ...inputProps
  } = props;
  const { control } = useFormContext();
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });
  function handleFocus(e: NativeSyntheticEvent<TextInputFocusEventData>) {
    if (typeof onFocus === "function") {
      onFocus(e);
    }
  }
  function handleBlurText() {
    onChange({
      ...value,
      phone: value.phone ? value.phone.replace(/^0+/, "") : value.phone,
    });
    onBlur();
    props.onBlur?.(value, onChange);
  }
  function handlePressItem(item: InputDropdownItemValue) {
    onChange({
      ...value,
      prefix: item.value,
    });
  }
  function handleChangeText(text: string) {
    onChange({
      ...value,
      phone: text,
    });
  }
  return (
    <InputGroup
      label={label}
      error={error?.message}
      leftHelper={leftHelper}
      rightHelper={rightHelper}
      disabled={disabled}
      style={style}
    >
      <View style={styles.input} row spacing="xs">
        <InputDropdown
          {...inputProps}
          testID={appendTestId(testIDPrefix, "phone.prefix")}
          disabled={disabled}
          onFocus={handleFocus}
          onPressItem={handlePressItem}
          items={phoneList}
          containerStyle={styles.prefix}
          style={styles.field}
          width={200}
          editable
          searchable
          onBlur={onBlur}
          value={value?.prefix}
          error={!!error?.message}
        />
        <View style={styles.field}>
          <Input
            {...inputProps}
            testID={appendTestId(testIDPrefix, "phone")}
            disabled={disabled}
            onBlur={handleBlurText}
            onFocus={handleFocus}
            style={styles.phone}
            onChangeText={handleChangeText}
            value={value?.phone}
            error={!!error?.message}
          />
        </View>
      </View>
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  input: {
    zIndex: 1,
  },
  prefix: {
    width: 80,
  },
  phone: {
    height: 40,
  },
  field: {
    flex: 1,
    paddingVertical: Token.spacing.xxs,
  },
});

const phoneList = countries.map((info) => ({
  label: info.countryName,
  value: `+${info.telPref}`,
}));
