"use client";
import { ComponentProps, useEffect, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputFocusEventData,
} from "react-native";

import IcSystemChevronDown from "@traveloka/icon-kit-web/react/IcSystemChevronDown";

import { JavaCurrencyValue } from "../../../utils";
import Token from "../../Token/Token";
import InputGroup from "../Input/InputGroup";
import InputDropdown from "../InputDropdown/InputDropdown";
import { InputDropdownItemValue } from "../InputDropdown/types";
import { FieldProps } from "../types";

export type BaggageAddOns = {
  id: string;
  baggageType: "PIECE" | "KG";
  baggageWeight: string;
  baggageQuantity: string;
  priceWithCurrency: JavaCurrencyValue;
};

type InputDropdownProps = ComponentProps<typeof InputDropdown>;

type BaggageItem = {
  label: string;
  value: BaggageAddOns;
};

export type BaggageFieldProps = {
  items: BaggageItem[];
  onPressItem?: (item: BaggageItem) => void;
} & FieldProps<BaggageAddOns> &
  Omit<InputDropdownProps, "value" | "items" | "onPressItem" | "defaultValue">;

export default function BaggageField(props: BaggageFieldProps) {
  const {
    defaultValue,
    name,
    validate,
    onFocus,
    label,
    startHelper,
    endHelper,
    disabled,
    style,
    onPressItem,
    items,
    testID,
    ...inputDropdownProps
  } = props;

  const itemMap = useMemo(() => {
    return items.reduce((obj, info) => {
      obj[info.value.id] = {
        label: info.label,
        value: info.value,
      };

      return obj;
    }, {} as Dictionary<BaggageItem>);
  }, [items]);

  const { control, resetField } = useFormContext();
  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  useEffect(() => {
    resetField(name, { defaultValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  const itemList = useMemo(() => {
    return items.map((item) => ({
      label: item.label,
      value: item.value.id,
    }));
  }, [items]);

  function handleFocus(e: NativeSyntheticEvent<TextInputFocusEventData>) {
    if (typeof onFocus === "function") {
      onFocus(e);
    }
  }

  function handlePressItem(
    item: InputDropdownItemValue,
    onChange: (_: unknown) => void
  ) {
    const baggageItem = itemMap[item.value];

    onChange(baggageItem.value);
    onPressItem && onPressItem(baggageItem);
  }

  return (
    <InputGroup
      label={label}
      error={error?.message}
      leftHelper={startHelper}
      rightHelper={endHelper}
      disabled={disabled}
      style={style}
    >
      <InputDropdown
        {...inputDropdownProps}
        testID={testID}
        disabled={disabled}
        error={error?.message}
        items={itemList}
        onFocus={handleFocus}
        onPressItem={(item) => handlePressItem(item, onChange)}
        onBlur={onBlur}
        value={value ? itemMap[value.id].label : ""}
        style={styles.input}
        endIcon={
          <IcSystemChevronDown
            color={Token.color.bluePrimary}
            width={24}
            height={24}
          />
        }
      />
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  input: {
    zIndex: 1,
  },
});
