import { StyleSheet } from "react-native";

import Text from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";

import PaginationTableRow from "./PaginationTableRow";
import { paginationTableStyles } from "./styles";
import { PaginationTableProps } from "./types";

type Props<T extends string> = PaginationTableProps<T> & {
  hasAction: boolean;
};

export default function PaginationTableHeader<T extends string>(
  props: Props<T>
) {
  const {
    headerCells,
    filterCells,
    columnOrder,
    columnStyle,
    hasAction,
    disabled,
  } = props;

  return (
    <>
      <PaginationTableRow
        disableHover
        style={[paginationTableStyles.columns, columnStyle]}
        columnLengths={columnOrder.map(
          (order) => headerCells[order]?.length ?? 0
        )}
        hasAction={hasAction}
      >
        {columnOrder.map((order, index) => {
          const cell = headerCells[order];

          if (!cell) return <View key={index} />;

          return (
            <View key={index}>
              {cell.render || (
                <Text
                  variant="ui-small-bold"
                  ink={disabled ? "black-muted" : "black-secondary"}
                >
                  {cell.label || String(cell.value)}
                </Text>
              )}
            </View>
          );
        })}
        {hasAction && <View style={paginationTableStyles.columns} />}
      </PaginationTableRow>
      {filterCells !== undefined &&
        Boolean(Object.keys(filterCells).length) && (
          <PaginationTableRow
            disableHover
            style={styles.filter}
            columnLengths={columnOrder.map(
              (order) => headerCells[order].length || 0
            )}
            hasAction={hasAction}
          >
            {columnOrder.map((order, index) => {
              const cell = filterCells[order];
              if (!cell) return <View key={index} />;
              return <View key={index}>{cell.render}</View>;
            })}
            {hasAction && <View style={paginationTableStyles.columns} />}
          </PaginationTableRow>
        )}
    </>
  );
}

const styles = StyleSheet.create({
  filter: {
    backgroundColor: Token.color.lightNeutral,
    borderBottomWidth: 1,
    borderBottomColor: Token.color.borderDivide,
    borderTopWidth: 1,
    borderTopColor: Token.color.borderDivide,
    zIndex: 30,
  },
});
