import { forwardRef, HTMLAttributes, ReactNode } from "react";

import styles from "./Text.module.css";

export type TextRef = HTMLSpanElement;

export type TextProps = {
  children?: ReactNode;
  /**
   * Defines color of the text.
   * @default 'primary'
   */
  ink?:
    | "black-primary"
    | "black-secondary"
    | "black-muted"
    | "black-neutral"
    | "white-primary"
    | "white-secondary"
    | "white-muted"
    | "white-neutral"
    | "interactive"
    | "destructive"
    | "highlight"
    | "price"
    | "positive"
    | "alert"
    | "warning";
  /**
   * Defines size & weight of the text.
   * @default 'ui-baseline'
   **/
  variant?:
    | "hero"
    | "display"
    | "headline"
    | "title-1"
    | "title-2"
    | "title-3"
    | "ui-large"
    | "ui-baseline"
    | "ui-small"
    | "ui-tiny"
    | "ui-large-bold"
    | "ui-baseline-bold"
    | "ui-small-bold"
    | "ui-tiny-bold"
    | "caption-tiny"
    | "caption-micro"
    | "button-large"
    | "button-medium"
    | "button-small";
  /**
   * Handle onPress event
   */
  onPress?: () => void;
} & HTMLAttributes<HTMLSpanElement>;

export default forwardRef<TextRef, TextProps>(function Text(props, ref) {
  const {
    ink = "black-primary",
    variant = "ui-baseline",
    onPress,
    className,
    ...rest
  } = props;

  const rootStyles = [styles.root, styles[variant], styles[ink], className];
  
  return (
    <span
      {...rest}
      ref={ref}
      className={rootStyles.join(" ")}
      onClick={onPress}
    />
  );
});
