import { StyleSheet } from "react-native";

import Token from "../../Token/Token";

export const paginationTableStyles = StyleSheet.create({
  container: {
    overflowX: "auto",
    // @ts-ignore
    maxWidth: "fit-content",
    minWidth: "100%",
  },
  wrapper: {
    // @ts-ignore
    minWidth: "fit-content",
  },
  actionCard: {
    position: "absolute",
    right: 16,
    top: -24,
    //@ts-ignore
    width: "max-content",
  },
  actionItem: {
    padding: Token.spacing.s,
  },
  cardWrapper: { zIndex: 4 },
  cellText: { marginVertical: "auto" },
  columns: {
    backgroundColor: Token.color.lightNeutral,
    paddingVertical: Token.spacing.xs,
    zIndex: 30,
  },
  loader: { marginHorizontal: "auto", paddingVertical: Token.spacing.m },
  menuWrapper: {
    marginVertical: "auto",
    alignItems: "flex-end",
    position: "relative",
  },
  paginationRow: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.s,
    borderTopColor: Token.color.borderDivide,
    borderTopWidth: Token.borderWidth.thick,
    zIndex: -1,
  },
  rows: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.s,
    backgroundColor: Token.color.lightPrimary,
  },
  rowsEven: {
    backgroundColor: Token.color.lightStain,
  },
});
